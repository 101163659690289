<template>
    <footer class="footer">
        <div class="container">
            <div class="footer__bottom">
                <div class="row">
                    <div class="col-lg-6">
                        <h3 class="footer__bottom__h3">COPYRIGHT 2022 SendHc & SpaceIS.pl<br><span
                                class="footer__bottom__info">Serwer SendHc.pl nie jest w żaden sposób powiązany z
                                Mojang AB.</span>
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
export default {
    name: 'FooterPartial',
}
</script>