<template>
    <Header />
    <section class="tos">
        <div class="container">
            <div class="tos__title">
                <h1 class="tos__title__h1">Regulamin</h1>
                <span class="tos__title__border"></span>
            </div>
            <div class="tos__content tos__content--hide fade-in animate__animated animate__fadeIn" id="tos1" style="display: block;">
                <h2 class="tos__content__h2">§1 Ogólne zasady panujące na serwerze</h2>
                <p>1.1 Wraz z dołączeniem na serwer, akceptujesz regulamin.</p>
                <p>1.2 Karą za łamanie, uchylanie się od regulaminu – ban lub wyrzucenie z serwera.</p>
                <p>1.3 Zabrania się używania modów/programów/tekstur które w znacznym stopniu ułatwiają rozgrywkę. (mini
                    mapa, optifine itp. są dozwolone).</p>
                <p>1.4 Administracja serwera może zbanować gracza, za czyny które nie są uwzględnione w regulaminie.</p>
                <p>1.5 Nieznajomość regulaminu nie zwalnia z jego przestrzegania</p>
                <p>1.6 Na serwerze jest dozwolone używanie słów potocznie uznawanych za wulgaryzmy, ale bez przesady</p>
                <p>1.7 Reklama innych serwerów jest zabroniona - wiadomości z adresami IP serwerów są automatycznie
                    usuwane</p>
                <h2 class="tos__content__h2">§2 Zasady panujące na serwerze</h2>
                <p>2.1 Każda osoba, która wchodzi na serwer musi zapoznać się z regulaminem.</p>
                <p>2.2 Administracja nie ma obowiązku nadawania backupów ekwipungu gracza</p>
                <p>2.3 Reklamowanie innych serwerów jest zabroniona - wiadomości z adresami IP serwerów są automatycznie
                    usuwane</p>
                <p>2.4.Zakaz wysyłania wszelkich linków bez zgody administracji.</p>
                <p>2.5 Zakaz używania programów wspomagających rozgrywkę na serwerze (np. cheaty). </p>
                <p>2.6 Zakaz wykorzystywania błędów serwera/gry</p>
                <p>2.7 Wszelkie umowy z graczami są organizowane na własne ryzyko.</p>
                <p>2.8 Zakazuje się wszelkiego rodzaju prowokacji, lub obrażania graczy i administracji oraz ich rodzin
                </p>
                <p>2.9 Zakaz przeszkadzania administracji w wypełnianiu ich obowiązków.</p>
                <p>2.10 Zakaz podawania danych osobowych administracji lub graczy bez ich zgody.</p>
                <p>2.11 Zakaz podszywania się pod graczy i administrację oraz powoływania się na znajomości przez
                    nieuprawnionych do tego graczy.</p>
                <p>2.12 Zakazane jest tworzenie multikont, za tworzenie multikont grozi ban pernmanentny na cały sezon.
                </p>
                <p>2.13 Zakaz udostępniania konta innej osobie, takie konto może zostać permanentnie zbanowane.</p>
                <p>2.14 Używanie obraźliwych i wulgarnych pseudonimów na serwerze grozi banem na zawsze.</p>
                <p>2.15 Współpraca z cheaterem grozi banem na 1 dzień, każdy cheater powinien być niezwłocznie zgłoszony
                    administracji serwera.</p>
                <p>2.16 Przyznanie się do niedozwolonych modyfikacji podczas sprawdzania skraca bana do dwóch dni.</p>
                <p>2.17 Zakaz szerzenia błednych / nieprawdziwych informacji na temat serwera.</p>
                <p>2.18 Gracz ma obowiązek mieć włączony czat, w przeciwnym wypadku może zostać zbanowany z powodu braku
                    odzewu podczas sprawdzania w celu wykrycia niedozwolonych modyfikacji.</p>
                <h2 class="tos__content__h2">§3 Administracja</h2>
                <p>3.1 Administrator nie musi podawać powodu dlaczego gracz jest sprawdzany.</p>
                <p>3.2 Administracja nie odpowiada za szkody poniesione na koncie w skutek kradzieży konta.</p>
                <p>3.3 Administracja ma prawo wymagać od graczy zainstalowania programów: Discord (może być w wersji
                    przeglądarkowej), TeamSpeak, TeamViewer, Anydesk.</p>
                <p>3.4 Administracja ma prawo poprosić gracza w każdej chwili o udostępnienie ss'ów oraz folderów/plików
                    które mogą zawierać potencjalne cheaty. Gracz jest zobowiązany do wykonania czynności.</p>
                <h2 class="tos__content__h2">§3 Czat</h2>
                <p>4.1 Obraza graczy / administracji / serwera / rodziny graczy lub administracji grozi mutem lub banem.
                </p>
                <p>4.2 Spamowanie / floodowanie na chacie / helpopie / i komendą /bug grozi mutem lub banem.</p>
                <p>4.3 Reklamowanie innych serwerów / stron internetowych / filmów / treści pornograficznych grozi mutem
                    lub banem.</p>
                <p>4.4 Zakaz ogłaszania ofert niezwiązanych z grą Minecraft na czacie globalnym.</p>
                <p>4.5 Jakiekolwiek groźby skierowane w kierunku graczy/administracji mogą być karane banem.</p>
                <p>4.6 Udostępnianie danych osobowych i prywatnych (nazwiska, numery telefonów, zdjęcia, Facebooki itp.)
                    grozi mutem lub banem.</p>
                <p>4.7 Udostępnianie adresów IP graczy grozi banem.</p>
                <p> </p>
                <center><a class="btn btn-primary btn-lg" href="https://sendhc.pl/polityka-prywatnosci" target="_blank"
                        role="button"><i class="fa fa-info-circle"></i> Polityka prywatności</a></center>
            </div>
        </div>
    </section>
    <Footer />
</template>
<script>
import { useMeta } from 'vue-meta'
import Header from './partials/Header.vue'
import Footer from './partials/Footer.vue' 
export default {
    name: 'RegulaminPage',
    components: {
        Header,
        Footer
    },
    mounted() {
        require('../assets/js/bany')
    },
    setup() {
        useMeta({
            title: 'Regulamin',
            bodyAttrs: {
                class: 'img-bg'
            }
        })
    }
}
</script>