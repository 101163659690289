<template>
    <header class="header">
        <Header />
        <div class="header_content">
            <img class="header_content__img"
                src="https://media.discordapp.net/attachments/964979460954804275/966399798951440384/pixlr-bg-result.png">
            <h1 class="header_content__h1">SENDHC.PL</h1>
            <br>
            <a class="btn btn-primary btn-lg" href="https://discord.gg/zZsUKndVdp" target="_blank" role="button"><i
                    class="fab fa-discord"></i> Dołącz na discorda</a>
        </div>
        <Admins />
        <Footer />
    </header>
</template>

<script>
import Header from './partials/Header.vue'
import Admins from './partials/Admins.vue'
import Footer from './partials/Footer.vue'
import { useMeta } from 'vue-meta'

export default {
    name: 'HomePage',
    components: {
        Header,
        Admins,
        Footer
    },
    setup() {
        useMeta({
            title: 'Strona główna '
        })
    }
}
</script>

<style>
.header {
    background: linear-gradient(0deg, var(--bodyBackground) 0%, #ffffff00 50%), var(--header-background);
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    min-height: 100vh;
}

.header_content {
    margin-top: 14vh;
    text-align: center;
}

.header_content__img {
    max-width: 80%;
    max-height: 300px;
    margin-bottom: 20px;
}

.header_content__h1 {
    font-size: 50px;
    color: var(--titleColor);
    font-weight: 600;
    letter-spacing: 2px;
    margin-top: 18px;
}

.header_content__p {
    color: var(--textColor);
    margin-bottom: 45px;
}

.header_content__btn {
    background-color: var(--specialElement);
    font-size: 20px;
    color: var(--titleColor);
    border-radius: var(--borderRadius);
    padding: 10px 0px;
    width: 200px;
    margin-right: 20px;
    text-decoration: none;
    transition: .3s;
    display: inline-block;
}

.header_content__btn:hover {
    transform: scale(1.05);
    transition: .3s;
    color: var(--titleColor);
}

.trailer__title__h1 {
    font-size: 30px;
    color: var(--titleColor);
    margin-bottom: 60px;
    margin-top: 40px;
}

.trailer__title__border {
    background-color: var(--specialElement);
    height: 6px !important;
    width: 70px;
    position: absolute;
    margin-top: -80px;
    margin-left: 260px;
    border-radius: var(--borderRadius);
}

.trailer__content__desc {
    padding: 40px;
}

.trailer__content__desc__btn {
    background-color: var(--specialElement);
    font-size: 16px;
    color: var(--titleColor);
    border-radius: var(--borderRadius);
    padding: 10px 0px;
    width: 200px;
    margin-right: 20px;
    text-decoration: none;
    transition: .3s;
    display: block;
    text-align: center;
    margin-bottom: 100px;
    margin-top: 20px;
}

.trailer__content__desc__btn:hover {
    transform: scale(1.05);
    transition: .3s;
    color: var(--titleColor);
}

.trailer__content iframe {
    border-radius: var(--borderRadius);
}

.admins__title__h1 {
    font-size: 30px;
    color: var(--titleColor);
    margin-bottom: 60px;
    margin-top: 40px;
}

.admins__title__border {
    background-color: var(--specialElement);
    height: 6px !important;
    width: 70px;
    position: absolute;
    margin-top: -80px;
    margin-left: 290px;
    border-radius: var(--borderRadius);
}

.admins__content__card {
    display: flex;
    width: 100%;
    padding: 20px;
    background-color: var(--secondBackgroundColor);
    margin-bottom: 20px;
    border-radius: var(--borderRadius);
}

.admins__content__card__left {
    width: 20%;
}

.admins__content__card__right {
    width: 80%;
}

.admins__content__card__right__h5 {
    color: var(--titleColor);
}

.admins__content__card__right__h6 {
    color: var(--specialElement);
    margin-bottom: 0px;
}

@media only screen and (max-width: 991px) {
    .header_content__btn {
        margin-bottom: 10px;
    }

    .header_content__img {
        max-width: 60%;
    }

    .header_content {
        margin-top: 8vh;
        text-align: center;
    }

    .trailer__content__desc {
        padding: 20px 10px;
    }

    .admins__title__border,
    .tops__title__border,
    .trailer__title__border {
        display: none;
    }

    .admins__title__h1,
    .tops__title__h1,
    .trailer__title__h1 {
        text-align: center;
    }
}
</style>
