<template>
    <section class="admins">
        <div class="container">
            <div class="admins__title">
                <h1 class="admins__title__h1">ADMINISTRACJA</h1>
                <span class="admins__title__border"></span>
            </div>
            <div class="admins__content">
                <div class="row">
                    <div class="col-md-6 col-xl-4">
                        <div class="admins__content__card">
                            <div class="admins__content__card__left">
                                <img class="admins__content__card__img" src="https://minotar.net/helm/_99ms/50">
                            </div>
                            <div class="admins__content__card__right">
                                <h5 class="admins__content__card__right__h5">_99ms</h5>
                                <h6 class="admins__content__card__right__h6">ZARZĄD</h6>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-4">
                        <div class="admins__content__card">
                            <div class="admins__content__card__left">
                                <img class="admins__content__card__img" src="https://minotar.net/helm/PikaczuseK/50">
                            </div>
                            <div class="admins__content__card__right">
                                <h5 class="admins__content__card__right__h5"> 1337PikaczuseK</h5>
                                <h6 class="admins__content__card__right__h6">ZARZĄD</h6>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-4">
                        <div class="admins__content__card">
                            <div class="admins__content__card__left">
                                <img class="admins__content__card__img" src="https://minotar.net/helm/MrPieselusYT/50">
                            </div>
                            <div class="admins__content__card__right">
                                <h5 class="admins__content__card__right__h5">MrPieselusYT</h5>
                                <h6 class="admins__content__card__right__h6">ROOT</h6>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-4">
                        <div class="admins__content__card">
                            <div class="admins__content__card__left">
                                <img class="admins__content__card__img" src="https://minotar.net/helm/doslownie/50">
                            </div>
                            <div class="admins__content__card__right">
                                <h5 class="admins__content__card__right__h5">Doslownie</h5>
                                <h6 class="admins__content__card__right__h6">POMOCNIK</h6>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-4">
                        <div class="admins__content__card">
                            <div class="admins__content__card__left">
                                <img class="admins__content__card__img"
                                    src="https://minotar.net/helm/Browar_Tyskie_/50">
                            </div>
                            <div class="admins__content__card__right">
                                <h5 class="admins__content__card__right__h5">Browar_Tyskie_</h5>
                                <h6 class="admins__content__card__right__h6">POMOCNIK</h6>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-4">
                        <div class="admins__content__card">
                            <div class="admins__content__card__left">
                                <img class="admins__content__card__img" src="https://minotar.net/helm/Zigpe_/50">
                            </div>
                            <div class="admins__content__card__right">
                                <h5 class="admins__content__card__right__h5">Zigpe</h5>
                                <h6 class="admins__content__card__right__h6">POMOCNIK</h6>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-4">
                        <div class="admins__content__card">
                            <div class="admins__content__card__left">
                                <img class="admins__content__card__img" src="https://minotar.net/helm/8/50">
                            </div>
                            <div class="admins__content__card__right">
                                <h5 class="admins__content__card__right__h5">DemDios</h5>
                                <h6 class="admins__content__card__right__h6">POMOCNIK</h6>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-4">
                        <div class="admins__content__card">
                            <div class="admins__content__card__left">
                                <img class="admins__content__card__img" src="https://minotar.net/helm/Kvbus/50">
                            </div>
                            <div class="admins__content__card__right">
                                <h5 class="admins__content__card__right__h5">Kvbus</h5>
                                <h6 class="admins__content__card__right__h6">POMOCNIK</h6>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-4">
                        <div class="admins__content__card">
                            <div class="admins__content__card__left">
                                <img class="admins__content__card__img" src="https://minotar.net/helm/Peseu251/50">
                            </div>
                            <div class="admins__content__card__right">
                                <h5 class="admins__content__card__right__h5">Peseu251</h5>
                                <h6 class="admins__content__card__right__h6">POMOCNIK</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'AdminsPartial',
}
</script>
