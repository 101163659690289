<template>
    <Header />
    <section class="tops">
        <div class="container animate__animated animate__pulse">
            <h1 class="mt-5">
                <center>Ranking wyłączony!</center>
            </h1>
        </div>
    </section>
    <Footer />
</template>

<script>
import { useMeta } from 'vue-meta'
import Header from './partials/Header.vue'
import Footer from './partials/Footer.vue'
export default {
    name: 'RankingPage',
    components: {
        Header,
        Footer
    },
    setup() {
        useMeta({
            title: "Ranking",
            bodyAttrs: {
                class: 'img-bg'
            }
        })
    }
}
</script>

<style scoped>
</style>
