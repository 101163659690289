import jQuery from 'jquery'
import $ from 'jquery'

let actual_page = 1;
let btn_enabled = true;
jQuery(async () => {
  let b = await loadBans(1)
  if (b == 1) {
    $(".bans-div").append(
      `<span class="tops_content__btn load-more-btn">ZOBACZ WIĘCEJ</span>`
    );
  } else if(b == 2) {
    $(".bans-div").append(
      `<center><h3>To już wszystko...</h3></center>`
    );
    remove_btn()
  } else {
    $(".bans-div").html(
      `<h1>Wczytywanie banów zakończone niepowodzeniem!</h1>`
    );
  }

  $(".load-more-btn").click(async function () {
    if (btn_enabled) {
      btn_enabled = false;
      actual_page++
      let bb = await loadBans(actual_page)
      if (bb == 1) {
          btn_enabled = true;
      } else if(bb == 2) {
        $(".bans-div").append(
          `<center><h3>To już wszystko...</h3></center>`
        );
        remove_btn()
      } else {
        $(".bans-div").html(
          `<h1>Nie udało się wczytać więcej!</h1>`
        );
        remove_btn()
      }
    } else {
      alert("Poczekaj na załadowanie danych...")
    }
  });
});

async function loadBans(page) {
  return $.get(`https://api.mrpiesel.pl/sendhc/bany.php?p=${page}`)
  .then((data) => {
    let bany = [];
    data.bans.forEach((b) => {
      bany.push(`
              <tr>
                <td data-th="TYP">${b.type}</td>
                <td data-th="UŻYTKOWNIK">${b.username}</td>
                <td data-th="ADMINISTRATOR">${b.administrator}</td>
                <td data-th="WYGASA">${b.ends}</td>
                <td data-th="POWÓD">${b.reason.replace(
                  "None",
                  "Nieokreślony"
                )}</td>
              </tr>
              `);
    });
    let bantable = $(".bans-table")
    let newel = $(bany.toString()).hide();
    bantable.append(newel)
    newel.show(1000)
    if (actual_page < data.pages) {
        return 1;
    } else {
      return 2;
    }
  }).fail(function () {
    return 0;
  });
  
}

function remove_btn () {
  $(".load-more-btn").remove()
  btn_enabled = false;
}
