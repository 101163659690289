<template>
    <nav class="navbar navbar-expand-lg  navbar--transparent ">
        <a type="button" class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo03"
            aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
            <i class="fas fa-bars"></i>
        </a>
        <a class="navbar-brand">
            <svg class="header-content-online" xmlns="http://www.w3.org/2000/svg" width="10" height="10"
                viewBox="0 0 10 10">
                <circle id="Ellipse_1" data-name="Ellipse 1" cx="5" cy="5" r="5" fill="#00ff2b"></circle>
            </svg>
            Graczy online: <span id="online">Edycja wkrótce</span></a>
        <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
            <ul class="navbar-nav">
                <li class="nav-item">
                    <router-link class="nav-link" to="/">STRONA GŁÓWNA</router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link" to="/ranking">RANKINGI</router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link" to="/bany">LISTA BANÓW</router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link" to="/regulamin">REGULAMIN</router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link nav-link--shop" to="/404">SKLEP</router-link>
                    <!-- <a class="nav-link nav-link--shop" href="https://sendhc.pl/404">SKLEP</a> -->
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'HeaderPartial',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* NAVBAR */

.navbar-nav {
    margin-left: auto;
}

.navbar {
    background-color: var(--secondBackgroundColor);
    padding: 0px;
    padding-left: 7%;
    padding-right: 0%;
}

.navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 20px;
    padding-left: 20px;
    color: var(--textColor);
    font-weight: 600;
    padding: 25px 40px;
    letter-spacing: 1px;
}

.navbar-expand-lg .navbar-nav .nav-link:hover {
    color: var(--titleColor);
}

.navbar-expand-lg .navbar-nav .router-link-exact-active {
    color: var(--specialElement);
}

.navbar-brand {
    color: var(--textColor);
    letter-spacing: 1px;
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
}

.navbar .fa-bars {
    color: var(--textColor) !important;
    font-size: 24px;
}

.navbar-brand:hover {
    color: var(--textColor) !important;
}

.navbar span {
    color: var(--titleColor);
}

.navbar-brand:hover {
    color: var(--titleColor);
}

.nav-link--shop {
    background-color: var(--specialElement);
    color: var(--titleColor) !important;
    padding-left: 60px !important;
    padding-right: 60px !important;
    max-width: 180px;
}

.navbar--transparent {
    background: var(--navbarTransparent);
}

.header-content-online {
    animation: animate 3s linear infinite;
    border-radius: 100%;
    margin-right: 8px;
    margin-bottom: 3px;
    margin-right: 14px;
}

@keyframes animate {
    0% {
        box-shadow: 0 0 0 0 #67e872af, 0 0 0 0 #67e872af;
        transform: scale(0.70);
    }

    40% {
        box-shadow: 0 0 0 10px #ff6d4a00, 0 0 0 0 #67e872af;
        transform: scale(0.90);
    }

    80% {
        box-shadow: 0 0 0 10px #ff6d4a00, 0 0 0 10px #ff6d4a00;
        transform: scale(0.80);
    }

    100% {
        box-shadow: 0 0 0 0 #ff6d4a00, 0 0 0 10px #ff6d4a00;
        transform: scale(0.70);
    }
}

@media only screen and (max-width: 1400px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding: 25px 30px;
    }

    .navbar {
        padding-left: 5%;
    }
}

@media only screen and (max-width: 1300px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding: 20px 20px;
    }

    .navbar {
        padding-left: 2%;
    }
}

@media only screen and (max-width: 991px) {
    .navbar {
        padding: 14px;
        padding-left: 20px;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding: 20px 20px;
        padding-left: 0px;
    }

    .navbar--transparent {
        background-color: var(--secondBackgroundColor);
    }
}
</style>

