<template>
  <Header />
  <section class="tops">
    <div class="container animate__animated animate__pulse">
      <!-- <div class="tops__title">
        <h1 class="tops__title__h1">BANY<small style="font-size: 15px"> Lista przykładowa</small></h1>
        <span class="tops__title__border"></span>
      </div>
      <div class="tops__content bans-div">
        <table class="rwd-table bans-table">
          <tr>
            <th>TYP</th>
            <th>UŻYTKOWNIK</th>
            <th>ADMINISTRATOR</th>
            <th>WYGASA</th>
            <th>POWÓD</th>
          </tr>
        </table>
      </div> -->
      <h1 class="mt-5">
        <center>Lista banów wyłaczona!</center>
      </h1>
    </div>
  </section>
  <Footer />
</template>

<script>
import { useMeta } from 'vue-meta'
import Header from './partials/Header.vue'
import Footer from './partials/Footer.vue'
export default {
  name: 'BanyPage',
  components: {
    Header,
    Footer
  },
  mounted() {
    // require('../assets/js/bany')
  },
  setup() {
    useMeta({
      title: 'Bany',
      bodyAttrs: {
        class: 'img-bg'
      }
    })
  }
}
</script>

<style scoped>
@import "https://fonts.googleapis.com/css?family=Montserrat:300,400,700";

.rwd-table {
  margin: 1em 0;
  width: 100%;
}

.rwd-table tr {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.rwd-table th {
  display: none;
}

.rwd-table td {
  display: block;
}

.rwd-table td:first-child {
  padding-top: .5em;
}

.rwd-table td:last-child {
  padding-bottom: .5em;
}

.rwd-table td:before {
  content: attr(data-th) ": ";
  font-weight: bold;
  width: 6.5em;
  display: inline-block;
}

@media (min-width: 480px) {
  .rwd-table td:before {
    display: none;
  }
}

.rwd-table th,
.rwd-table td {
  text-align: left;
}

@media (min-width: 480px) {

  .rwd-table th,
  .rwd-table td {
    display: table-cell;
    padding: .25em .5em;
  }

  .rwd-table th:first-child,
  .rwd-table td:first-child {
    padding-left: 0;
  }

  .rwd-table th:last-child,
  .rwd-table td:last-child {
    padding-right: 0;
  }
}

td::before {
  margin-right: 15%;
}

.rwd-table {
  background: var(--secondBackgroundColor);
  color: #fff;
  border-radius: 2px;
  overflow: hidden;
}

.rwd-table tr {
  border-color: var(--secondBackgroundColor);
}

.rwd-table th,
.rwd-table td {
  margin: .5em 1em;
}

@media (min-width: 480px) {

  .rwd-table th,
  .rwd-table td {
    padding: 1em !important;
  }

  td::before {
    margin-right: 0px;
  }
}

.rwd-table th,
.rwd-table td:before {
  color: var(--specialElement);
}
</style>
