import { createRouter, createWebHistory } from 'vue-router'
import Home from './components/Home.vue'
import Bany from './components/Bany.vue'
import Ranking from './components/Ranking.vue'
import Regulamin from './components/Regulamin.vue'
const routes = [
    { path: '/', component: Home },
    { path: '/bany', component: Bany, meta: { title: 'Skills - MyApp' } },
    { path: '/ranking', component: Ranking },
    { path: '/regulamin', component: Regulamin },
  ]

export const router = createRouter({
  history: createWebHistory(),
  routes
})
