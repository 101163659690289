<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content} | SendHc` : `SendHc` }}</template>
  </metainfo>
  <router-view></router-view>
</template>

<script>
export default {
    name: "App"
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css');
:root {
  --bodyBackground: #141414;
  --titleColor: #ffffff;
  --textColor: #cacaca;
  --specialElement: #EC9324;
  --secondBackgroundColor: #1a1b1ab6;
  --navbarTransparent: #1b18187e;
  --border: #484848;
  --titleSize: 30px;
  --textSize: 16px;
  --borderRadius: 0px;
  --header-background: url(https://i.imgur.com/7J5uUfl.png);
  /* --header-background: url(https://i.ibb.co/YBVw4LK/7J5uUfl.png); */

}

/* MOTYW CIEMNY - KOLORYSTYKA CZERWONA */

/* :root {
    --bodyBackground: #1e1b1b;
    --titleColor: #ffffff;
    --textColor: #cacaca;
    --specialElement: #ee4540;
    --secondBackgroundColor: #1b1818;
    --navbarTransparent: #1b18187e;
    --border: #484848;
    --paymentsBorder: #272323;
    --titleSize: 30px;
    --textSize: 16px;
    --borderRadius: 2px;
    --header-background: url(https://i.imgur.com/J1j98hk.png);
} */


/* MOTYW CIEMNY - KOLORYSTYKA CIEMNOZIELONA */


/* :root {
    --bodyBackground: #141414;
    --titleColor: #ffffff;
    --textColor: #cacaca;
    --specialElement: #84ce3b;
    --secondBackgroundColor: #1a1b1a;
    --navbarTransparent: #1b18187e;
    --border: #484848;
    --titleSize: 30px;
    --textSize: 16px;
    --borderRadius: 0px;
    --header-background: url(../img/header_2.jpg);
} */


/* MOTYW CIEMNY - KOLORYSTYKA POMARAŃCZOWA */


/* :root {
    --bodyBackground: #111111;
    --titleColor: #ffffff;
    --textColor: #cacaca;
    --specialElement: #F99C17;
    --secondBackgroundColor: #181716;
    --navbarTransparent: #1b18187e;
    --border: #484848;
    --titleSize: 30px;
    --textSize: 16px;
    --borderRadius: 0px;
    --header-background: url(../img/header_3.jpg);
} */


/* MOTYW JASNY - KOLORYSTYKA NIEBIESKA */


/* :root {
    --bodyBackground: #fff;
    --titleColor: #000;
    --textColor: #202020;
    --specialElement: #18BCD9;
    --secondBackgroundColor: #F5F5F5;
    --navbarTransparent: #ffffff1a;
    --border: #fff;
    --titleSize: 30px;
    --textSize: 16px;
    --borderRadius: 0px;
    --header-background: url(../img/header_4.jpg);
} */


/* MOTYW CIEMNY - KOLORYSTYKA NIEBIESKA */


/* :root {
    --bodyBackground: #0e0e0e;
    --titleColor: #ffffff;
    --textColor: #cacaca;
    --specialElement: #1c8dd8;
    --secondBackgroundColor: #131314;
    --navbarTransparent: #1b18187e;
    --border: #484848;
    --titleSize: 30px;
    --textSize: 16px;
    --borderRadius: 0px;
    --header-background: url(../img/header_5.jpg);
} */

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--specialElement);
}

body {
  background-color: var(--bodyBackground);
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
  color: var(--textColor);
}

.row--center {
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

h1,
h2,
h3,
h4,
h5 {
  color: #FFF;
}

/* SERVERS */

.servers__title__h1 {
  font-size: 30px;
  color: var(--titleColor);
  margin-bottom: 60px;
  margin-top: 40px;
}

.servers__title__border {
  background-color: var(--specialElement);
  height: 6px !important;
  width: 70px;
  position: absolute;
  margin-top: -80px;
  margin-left: 230px;
  border-radius: var(--borderRadius);
}

.servers__card {
  padding: 30px;
  margin: 30px 0px;
  margin-top: 0px;
  margin-bottom: 60px;
  background-color: var(--secondBackgroundColor);
  text-align: center;
  border-radius: var(--borderRadius);
}

.servers__card_img {
  max-width: 170px;
  max-height: 270px;
  margin-bottom: 20px;
}

.servers__card__h2 {
  font-size: 30px;
  color: var(--titleColor);
  margin-bottom: 18px;
}

.servers__card__btn {
  background-color: var(--specialElement);
  color: var(--titleColor);
  padding-top: 10px;
  padding-bottom: 10px;
  border: 0px;
  font-weight: 600;
  letter-spacing: 1px;
  width: 200px;
  transition: 0.3s;
  border-radius: var(--borderRadius);
  text-decoration: none;
  display: inline-block;
}

.servers__card__btn:hover {
  transition: 0.3s;
  transform: scale(1.1);
  color: var(--titleColor);
}

@media only screen and (max-width: 991px) {
  .servers__title__border {
    display: none;
  }

  .servers__title__h1 {
    text-align: center;
  }
}


/* SHOP */

.shop__title__h1 {
  font-size: 30px;
  color: var(--titleColor);
  margin-bottom: 60px;
  margin-top: 40px;
}

.shop__title__border {
  background-color: var(--specialElement);
  height: 6px !important;
  width: 70px;
  position: absolute;
  margin-top: -80px;
  margin-left: 310px;
  border-radius: var(--borderRadius);
}

.shop__card {
  padding: 30px;
  margin-top: 0px;
  margin-bottom: 60px;
  background-color: var(--secondBackgroundColor);
  text-align: center;
  border-radius: var(--borderRadius);
}

.shop__card_img {
  max-width: 170px;
  max-height: 270px;
  margin-bottom: 20px;
}

.shop__card__h2 {
  font-size: 26px;
  letter-spacing: 1px;
  color: var(--titleColor);
  font-weight: 600;
}

.shop__card__description__p {
  color: var(--textColor);
}

.shop__card__btn {
  background-color: var(--specialElement);
  color: var(--titleColor);
  padding-top: 10px;
  padding-bottom: 10px;
  border: 0px;
  font-weight: 600;
  letter-spacing: 1px;
  width: 200px;
  transition: 0.3s;
  border-radius: var(--borderRadius);
  text-decoration: none;
  display: inline-block;
}

.shop__card__btn:hover {
  transition: 0.3s;
  transform: scale(1.1);
  color: var(--titleColor);
}

.shop__card__description {
  height: 85px;
  overflow-y: auto;
  margin: 20px 0px;
  padding: 0px 5px;
}

.shop__card__description::-webkit-scrollbar {
  width: 4px;
  border-radius: var(--borderRadius);
}

.shop__card__description__p {
  font-size: 14px;
  margin-bottom: 0px;
}

.shop__card--col3 {
  margin-bottom: 60px;
}

.shop__card--col6 .shop__card__description {
  height: 148px;
  text-align: left;
}

@media only screen and (max-width: 991px) {

  .shop__title__border,
  .last__title__border {
    display: none;
  }

  .shop__title__h1,
  .last__title__h1 {
    text-align: center;
  }
}


/* LAST */

.last__title__h1 {
  font-size: 30px;
  color: var(--titleColor);
  margin-bottom: 60px;
  margin-top: 40px;
}

.last__title__border {
  background-color: var(--specialElement);
  height: 6px !important;
  width: 70px;
  position: absolute;
  margin-top: -80px;
  margin-left: 290px;
  border-radius: var(--borderRadius);
}

.last__content__img {
  margin: 6px;
  filter: grayscale(40%);
  transition: .3s;
}

.last__content__img:hover {
  margin: 6px;
  filter: grayscale(0%);
  transition: .3s;
}


/* TOPS */

.tops {
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  padding-top: 60px;
}

.tops__content__h2 {
  font-size: 24px;
  color: #fff;
  margin-bottom: 10px;
  font-style: italic;
  text-align: center;
  font-weight: 800;
}

.tops__content__h3 {
  font-style: italic;
  text-align: center;
  color: #5959b3;
  font-size: 21px;
  margin-bottom: 30px;
  font-weight: 400;
}

.tops__title__border {
  height: 6px !important;
  width: 70px;
  position: absolute;
  margin-top: -80px;
  margin-left: 190px;
  border-radius: 30px;
}

.tops_content__card {
  background-color: var(--secondBackgroundColor);
  padding: 5px 20px;
  height: 65px;
  margin-bottom: 20px;
  border-radius: 30px;
  border: 3px solid var(--specialElement);
}

.tops__card {
  background-color: var(--secondBackgroundColor);
  padding: 30px;
  border-radius: 30px;
  margin: 30px;
  padding-left: 40px;
}

.tops_content__card__span {
  background-color: var(--specialElement);
  width: 50px;
  height: 50px;
  display: block;
  font-size: 20px;
  padding-top: 10px;
  text-align: center;
  border-radius: 30px;
  float: left;
  position: absolute;
  margin-left: -35px;
}

.tops_content__card__img {
  margin-left: 30px;
  border-radius: 5px;
}

.tops_content__card__h5 {
  font-size: 20px;
  color: #fff;
  padding-top: 12px;
}

.tops_content__card__h5--right {
  text-align: right;
}

.tops_content__btn:hover {
  transform: scale(1.05);
  transition: .3s;
  color: #fff;
}

@media only screen and (max-width: 991px) {
  .tops_content__card {
    height: auto;
  }

  .navbar {
    padding: 20px;
  }

  .tops__card {
    padding: 10px;
    margin: 10px;
    padding-left: 0px;
  }
}

@media only screen and (max-width: 575px) {
  .tops_content__card__img {
    float: right;
  }

  .tops_content__card__span {
    margin-left: 3px;
  }
}


/* BANS */

.bans__title__h1 {
  font-size: 30px;
  color: var(--titleColor);
  margin-bottom: 60px;
  margin-top: 40px;
}

.bans__title__border {
  background-color: var(--specialElement);
  height: 6px !important;
  width: 70px;
  position: absolute;
  margin-top: -80px;
  margin-left: 390px;
  border-radius: var(--borderRadius);
}

.bans_content__card {
  background-color: var(--secondBackgroundColor);
  padding: 20px;
  height: 90px;
  margin-bottom: 20px;
  border-radius: var(--borderRadius);
}

.bans_content__card__span {
  background-color: var(--specialElement);
  height: 50px;
  width: 220px;
  font-size: 20px;
  padding-top: 10px;
  text-align: center;
  color: var(--titleColor);
  border-radius: var(--borderRadius);
  float: right;
}

.bans_content__card__img {
  padding-left: 15px;
}

.bans_content__card__h5 {
  font-size: 20px;
  color: var(--titleColor);
  padding-top: 15px;
}

.bans_content__card__h5--right {
  text-align: right;
}

.bans_content__card__h6 {
  padding-top: 17px;
  font-size: 14px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 991px) {
  .bans_content__card {
    height: auto;
  }

  .bans_content__card__span {
    float: left;
  }

  .bans_content__card__h5 {
    margin-bottom: 20px;
  }

  .bans__title__border {
    display: none;
  }

  .bans__title__h1 {
    text-align: center;
    font-size: 26px;
  }
}


/* TOS */

.tos__title__h1 {
  font-size: 30px;
  color: var(--titleColor);
  margin-bottom: 60px;
  margin-top: 40px;
}

.tos__title__border {
  background-color: var(--specialElement);
  height: 6px !important;
  width: 70px;
  position: absolute;
  margin-top: -80px;
  margin-left: 230px;
  border-radius: var(--borderRadius);
}

.tos__nav__btn {
  background-color: var(--specialElement);
  font-size: 20px;
  color: var(--titleColor);
  border-radius: var(--borderRadius);
  padding: 10px 40px;
  margin-right: 20px;
  text-decoration: none;
  transition: .3s;
  display: inline-block;
  opacity: 0.5;
}

.tos__nav__btn--active {
  opacity: 1;
}

.tos__nav__btn:hover {
  color: var(--titleColor);
  transform: scale(1.05);
  transition: .3s;
}

.tos__content {
  position: relative;
  background-color: var(--secondBackgroundColor);
  padding: 10px 40px;
  border-radius: var(--borderRadius);
  margin-top: 50px;
}

.tos__content--hide {
  display: none;
}

.tos__content__h2 {
  color: var(--titleColor);
  margin-bottom: 20px;
  margin-top: 40px;
  font-size: 20px;
}

@media only screen and (max-width: 991px) {
  .tos__nav__btn {
    margin-bottom: 20px;
  }

  .tos__title__border {
    display: none;
  }

  .tos__title__h1 {
    text-align: center;
  }

  .tos__content {
    padding: 20px;
  }
}


/* VOUCHER */

.voucher__content {
  background-color: var(--secondBackgroundColor);
  padding: 10px 40px;
  border-radius: var(--borderRadius);
  margin-top: 50px;
  padding-bottom: 0px;
}

.voucher__title__h1 {
  font-size: 30px;
  color: var(--titleColor);
  margin-bottom: 60px;
  margin-top: 40px;
}

.voucher__title__border {
  background-color: var(--specialElement);
  height: 6px !important;
  width: 70px;
  position: absolute;
  margin-top: -80px;
  margin-left: 380px;
  border-radius: var(--borderRadius);
}

.voucher__content__h4 {
  font-size: 22px;
  color: var(--titleColor);
  margin-bottom: 20px;
  margin-top: 40px;
}

.voucher__content__input {
  height: 50px;
  background-color: var(--bodyBackground);
  border-radius: var(--borderRadius);
  border: 0px;
  outline: 0;
  margin: 0;
  color: #ffffff;
  padding-left: 10px;
  border-radius: 0px !important;
  margin-bottom: 20px;
  width: 90%;
}

.voucher__content__input:active {
  background-color: var(--bodyBackground);
  color: #fff;
}

.voucher__content__input:focus {
  background-color: var(--bodyBackground);
  color: #fff;
  outline: none;
  box-shadow: none;
}

.voucher__content__btn {
  background-color: var(--specialElement);
  font-size: 16px;
  color: var(--titleColor);
  border-radius: var(--borderRadius);
  padding: 10px 0px;
  width: 200px;
  margin-right: 20px;
  text-decoration: none;
  transition: .3s;
  display: block;
  text-align: center;
  margin-bottom: 60px;
  margin-top: 20px;
  border: 0px;
}

.voucher__content__btn:hover {
  transform: scale(1.05);
  transition: .3s;
  color: var(--titleColor);
}

.voucher__content__img {
  max-width: 100%;
  position: absolute;
  max-height: 490px;
  margin-left: -185px;
  margin-top: -55px;
}

@media only screen and (max-width: 1199px) {
  .voucher__content__img {
    display: none;
  }

  .voucher__content__input {
    width: 100%;
  }
}

@media only screen and (max-width: 991px) {
  .voucher__title__border {
    display: none;
  }

  .voucher__title__h1 {
    text-align: center;
    font-size: 26px;
  }
}


/* STATUS */

.status__content {
  background-color: var(--secondBackgroundColor);
  padding: 10px 40px;
  border-radius: var(--borderRadius);
  margin-top: 130px;
  padding-bottom: 0px;
}

.status__title__h1 {
  font-size: 30px;
  color: var(--titleColor);
  margin-bottom: 60px;
  margin-top: 40px;
}

.status__title__border {
  background-color: var(--specialElement);
  height: 6px !important;
  width: 70px;
  position: absolute;
  margin-top: -80px;
  margin-left: 360px;
  border-radius: var(--borderRadius);
}

.status__content__card {
  padding: 30px;
  background-color: var(--secondBackgroundColor);
}

.status__content__card__h5 {
  font-size: 19px;
  margin-bottom: 40px;
}

.status__content__card b {
  color: #e9c523;
}

.status__content__card--paid b {
  color: #78cd32;
}

.status__content__card--fail b {
  color: #f33131;
}

.status__content__card__h6 {
  font-size: 14px;
  margin-top: 10px;
}

@media only screen and (max-width: 991px) {
  .status__title__h1 {
    font-size: 25px;
    text-align: center;
  }

  .status__title__border {
    display: none;
  }

  .status__content {
    margin-top: 70px;
  }
}


/* PAYMENTS */

.payments__title__h1 {
  font-size: 30px;
  color: var(--titleColor);
  margin-bottom: 60px;
  margin-top: 40px;
}

.payments__title__border {
  background-color: var(--specialElement);
  height: 6px !important;
  width: 70px;
  position: absolute;
  margin-top: -80px;
  margin-left: 240px;
  border-radius: var(--borderRadius);
}

@media only screen and (max-width: 991px) {
  .payments__title__h1 {
    text-align: center;
  }

  .payments__title__border {
    display: none;
  }
}

.payments__content__product {
  background-color: var(--secondBackgroundColor);
  padding: 40px;
  min-height: 700px;
  border-radius: var(--borderRadius);
}

.payments__content__label {
  color: var(--textColor);
  font-size: 14px;
  display: block;
}

.payments__content__product__item {
  text-align: center;
}

.payments__content__product__item__img {
  max-width: 220px;
  max-height: 220px;
}

.payments__content__produc__description {
  max-height: 240px;
  min-height: 240px;
  overflow-y: auto;
}

.payments__content__product__h3 {
  color: var(--titleColor);
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 24x;
  text-align: center;
  font-weight: 600;
}

.payments__content__product__description__p {
  color: var(--textSize);
  font-size: 16px;
}

.payments__content__code {
  margin-top: 20px;
}

.payments__content__code__input {
  height: 50px;
  background-color: var(--bodyBackground);
  border-radius: var(--borderRadius);
  border: 0px;
  outline: 0;
  margin: 0;
  color: #ffffff;
  padding-left: 10px;
  border-radius: var(--borderRadius);
  width: 75%;
  float: left;
}

.payments__content__code__input:active {
  background-color: var(--bodyBackground);
  color: #fff;
}

.payments__content__code__input:focus {
  background-color: var(--bodyBackground);
  color: #fff;
  outline: none;
  box-shadow: none;
}

.payments__content__code__btn {
  background-color: var(--bodyBackground);
  color: var(--titleColor) !important;
  width: 55px;
  height: 50px;
  font-size: 16px;
  text-align: center;
  border: 0;
  float: right;
  transition: .3s;
  border-radius: var(--borderRadius);
}

.payments__content__code__btn:hover {
  background-color: var(--specialElement);
  transition: .3s;
}

.payments__content__forms__title__number {
  background-color: var(--secondBackgroundColor);
  width: 50px;
  height: 50px;
  display: block;
  font-size: 20px;
  padding-top: 10px;
  text-align: center;
  color: var(--titleColor);
  border-radius: var(--borderRadius);
  float: left;
  font-weight: 600;
}

.payments__content__forms__title__h3 {
  color: var(--titleColor);
  font-size: 22px;
  padding-left: 20px;
  padding-top: 12px;
  display: inline-block;
}

.payments__content__forms__user {
  margin: 30px 0px;
}

.payments__content__forms__user__input {
  height: 50px;
  background-color: var(--secondBackgroundColor);
  border-radius: var(--borderRadius);
  border: 0px;
  outline: 0;
  margin: 0;
  color: #ffffff;
  padding-left: 10px;
  border-radius: var(--borderRadius);
  width: 100%;
}

.payments__content__forms__user__input:active {
  background-color: var(--secondBackgroundColor);
  color: #fff;
}

.payments__content__forms__user__input:focus {
  background-color: var(--secondBackgroundColor);
  color: #fff;
  outline: none;
  box-shadow: none;
}

.payments__content__forms__method {
  margin: 30px 0px;
}

.payments__content__forms__method__card {
  text-align: center;
  background-color: var(--secondBackgroundColor);
  padding: 25px 0px;
  min-width: 120px;
  max-width: 410px;
  border: 2px solid var(--secondBackgroundColor);
  transition: .3s;
  border-radius: var(--borderRadius);
  margin-bottom: 10px;
}

.payments__content__forms__method__card:hover {
  border: 2px solid var(--paymentsBorder);
  transform: scale(1.05);
  transition: .3s;
}

.payments__content__forms__method .active {
  color: var(--textColor);
  border: 2px solid var(--paymentsBorder);
}

.payments__content__forms__method .active .payments__content__forms__method__card__icon {
  color: var(--specialElement);
}

.payments__content__forms__method__card__icon {
  font-size: 30px;
  color: var(--textColor);
  margin-bottom: 10px;
}

.payments__content__forms__method__card__a {
  color: var(--textColor);
  text-decoration: none;
  display: block;
  margin-bottom: 0px;
  font-size: 16px;
}

.payments__content__forms__method__card__p {
  color: var(--textColor);
  display: block;
  margin-bottom: 0px;
  margin-top: -4px;
  font-size: 12px;
  font-weight: 600;
}

.payments__content__forms__method__card__a:hover {
  color: var(--textColor);
  text-decoration: none;
}

.payments__content__forms__method .active .payments__content__forms__method__card__a {
  color: var(--specialElement);
}

.payments__content__forms__left {
  float: left;
  color: var(--textColor);
  text-decoration: none;
}

.payments__content__forms__left:hover {
  float: left;
  color: var(--textColor);
  text-decoration: none;
}

.payments__content__forms__right {
  float: right;
  color: var(--textColor);
  text-decoration: none;
}

.payments__content__forms__right:hover {
  float: right;
  color: var(--textColor);
  text-decoration: none;
}

.payments__content__forms__title--second {
  margin-bottom: 30px;
}

.payments__content__forms__range {
  margin-bottom: 20px;
}

.payments__content__forms__range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background: var(--specialElement);
  cursor: pointer;
  outline: none !important;
  box-shadow: none !important;
  border: 0px solid;
  border-radius: var(--borderRadius);
}

.payments__content__forms__range::-webkit-slider-runnable-track {
  background: var(--secondBackgroundColor);
  height: 12px;
  border-radius: var(--borderRadius);
}

.payments__content__forms__range::-webkit-slider-thumb {
  width: 20px;
  height: 20px;
  background: var(--specialElement);
  border-radius: var(--borderRadius);
}

.payments__content__forms__range::-webkit-slider-thumb:hover {
  width: 20px;
  height: 20px;
  background: var(--specialElement);
  border-radius: var(--borderRadius);
}

.payments__content__forms__range::-webkit-slider-thumb:active {
  width: 20px;
  height: 20px;
  background: var(--specialElement);
  border-radius: var(--borderRadius);
}

.payments__content__forms__variant__info {
  padding: 12px 0px;
  background-color: var(--secondBackgroundColor);
  text-align: center;
  width: 100%;
  margin-bottom: 30px;
  border-radius: var(--borderRadius);
}

.payments__content__forms_price__checkbox:checked {
  background-color: var(--specialElement);
  border-radius: var(--borderRadius);
  outline: none !important;
  box-shadow: none !important;
  border: 2px solid var(--specialElement);
}

.payments__content__forms_price__checkbox:hover {
  border-radius: var(--borderRadius);
  outline: none !important;
  box-shadow: none !important;
  border: 2px solid var(--specialElement);
}

.payments__content__forms_price__checkbox:active {
  border-radius: var(--borderRadius);
  outline: none !important;
  box-shadow: none !important;
  border: 2px solid var(--specialElement);
}

.payments__content__forms_price__checkbox {
  border-radius: var(--borderRadius) !important;
  background-color: var(--secondBackgroundColor);
  outline: none !important;
  box-shadow: none !important;
  font-size: 20px;
  border: 2px solid var(--specialElement);
}

.payments__content__forms_price a {
  color: var(--titleColor);
  text-decoration: none;
}

.payments__content__forms_price a:hover {
  color: var(--titleColor);
  text-decoration: none;
}

.payments__content__forms_price__label {
  margin-top: 4px;
  display: inline-block;
}

.payments__content__forms_price__label_operator {
  margin-top: 4px;
  display: block;
  margin-bottom: 20px;
}

.payments__content__forms__price__info {
  float: left;
  margin-top: 20px;
}

.payments__content__forms_price__btn {
  background-color: var(--specialElement);
  color: var(--titleColor) !important;
  width: 300px;
  padding: 15px 0px;
  font-size: 16px;
  text-align: center;
  border: 0;
  float: right;
  margin-top: 20px;
  border-radius: var(--borderRadius);
}

@media only screen and (max-width: 1199px) {
  .payments__content__product {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 991px) {
  .payments__content__forms__user__input {
    margin-bottom: 10px;
  }

  .payments__content__forms__method__card {
    max-width: 50%;
    min-width: 150px;
    margin: auto;
    margin-bottom: 20px;
  }

  .payments__content__forms_price__btn {
    float: left;
  }
}

@media only screen and (max-width: 440px) {
  .payments__content__forms_price__label {
    width: 90%;
  }
}


/* FOOTER */


.footer {
  padding: 90px 0px 60px;
  /* background-color: var(--secondBackgroundColor); */
  margin-top: 150px;
}

.footer__left {
  text-align: center;
}

.footer__left__img {
  max-width: 210px;
  max-height: 210px;
  margin-top: 30px;
  transition: .3s;
  filter: grayscale(0.3);
}

.footer__left__img:hover {
  transition: .3s;
  transform: scale(1.05);
  filter: grayscale(0);
}

.footer__center {
  text-align: center;
  border-right: 4px solid var(--border);
  border-left: 4px solid var(--border);
  padding: 0px 50px;
  padding-bottom: 30px;
}

.footer__center__h3 {
  font-size: 22px;
  color: var(--titleColor);
  margin-bottom: 20px;
  display: block;
  font-weight: 600;
}

.footer__center__item {
  transition: .3s;
}

.footer__center__item:hover {
  transform: scale(1.05);
  transition: .3s;
}

.footer__center__item__h5 {
  font-size: 16px;
  color: var(--textColor);
  transition: .3s;
  margin-top: 10px;
}

.footer__center__item:hover .footer__center__item__h5 {
  color: var(--titleColor);
  transition: .3s;
}

.footer .fab {
  font-size: 35px;
  color: var(--textColor);
  margin-top: 30px;
  transition: .3s;
}

.footer__center__item:hover .fab {
  color: var(--titleColor);
  transition: .3s;
}

.footer__right {
  padding-left: 90px;
}

.footer__right__h3 {
  font-size: 22px;
  color: var(--titleColor);
  margin-bottom: 40px;
  font-weight: 600;
}

.footer__right__p {
  font-size: 16px;
  color: var(--textColor);
  text-decoration: none;
  margin-bottom: 7px;
  transition: 0.3s;
}

.footer__right a:hover {
  margin-left: 5px;
  transition: 0.3s;
  letter-spacing: 1px;
}

.footer__right a {
  font-size: 16px;
  color: var(--textColor);
  text-decoration: none !important;
  transition: 0.3s;
}

.footer__right a:hover {
  color: var(--titleColor);
}

.footer__bottom {
  padding-top: 35px;
  border-top: 4px solid var(--border);
  margin-top: 40px;
}

.footer__bottom__spaceis {
  float: right;
  font-size: 15px;
  opacity: 0.8;
}

.footer__bottom__spaceis__img {
  width: 100px;
  margin-left: 30px;
  opacity: 0.7;
  transition: 0.4s;
}

.footer__bottom__info {
  opacity: 0.3;
}

.footer__bottom__spaceis__img:hover {
  opacity: 1;
  transition: 0.4s;
}

.footer__bottom__h3 {
  font-size: 16px;
  color: var(--textColor);
}

.footer__bottom__a {
  color: var(--titleColor);
  text-decoration: none;
}

.footer__bottom__a:hover {
  color: var(--titleColor);
}

.img-bg {
  background-image: var(--header-background);
  background-repeat: no-repeat;
  background-size: auto;
}

@media only screen and (max-width: 991px) {
  .footer__bottom {
    padding-top: 55px;
    border-top: 4px solid var(--border);
    margin-top: 40px;
  }

  .footer__center {
    border-right: 0px;
    border-left: 0px;
    padding: 0px 10px;
  }

  .footer__right {
    padding-left: 0px;
    text-align: center;
  }

  .footer__center__h3 {
    margin-top: 40px;
  }

  .footer__right__h3 {
    margin-top: 40px;
  }

  .footer__spaceis {
    margin-top: 40px;
    float: left;
  }

  .footer__spaceis__img {
    margin-right: 100px;
    width: 100px;
    margin-left: 20px;
  }
}

@media only screen and (max-width: 488px) {
  .footer__spaceis {
    margin-top: 40px;
    float: left;
  }

  .footer__spaceis__img {
    margin-right: 100px;
    width: 100px;
    margin-left: 0px;
    margin-top: 10px;
  }
}
</style>